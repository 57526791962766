<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div>
      <b-button
        v-if="true"
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.push({name: 'home-orders-rental-sales-list-v2'})"
      >
        {{ $t('Back to List') }}
      </b-button>
      <b-button
        v-else
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
      >
        <feather-icon
          icon="LCancelIcon"
          size="16"
        />
        {{ $t('Cancel') }}
      </b-button>
    </div>
    <div
      class="d-flex"
      style="gap: 8px"
    >
      <b-button
        disabled
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3"
      >
        <feather-icon icon="LPrintIcon" />
        {{ $t('Print') }}
      </b-button>
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3"
        @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT)"
      >
        {{ $t(FORM_BUTTON_TITLE_SAVE_AS_DRAFT) }}
      </b-button>
      <b-button
        variant="danger"
        class="font-medium-1 font-weight-bold px-3"
        :disabled="!isFormChangedAsPlaceOrder"
        @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS)"
      >
        {{ $t('Hold Assets') }}
      </b-button>
      <b-button
        variant="secondary"
        class="font-medium-1 font-weight-bold px-3"
        :disabled="!isFormChangedAsPlaceOrder"
        @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL)"
      >
        {{ $t('Send by Email') }}
      </b-button>
      <b-button
        variant="success"
        class="font-medium-1 font-weight-bold px-3"
        :disabled="!isFormChangedAsPlaceOrder"
        @click="submit(DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER)"
      >
        {{ $t('Place Order') }}
      </b-button>
    </div>
    <b-s-warning-modal
      ref="confirm-modal"
      @onNextTick="onClear"
    />
  </div>
</template>
<script>

import { BButton } from 'bootstrap-vue'
import config from '@/views/main/orders/view/new-rental-sales/config'
import { format } from 'date-fns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FormAction',
  components: { BButton },

  computed: {
    ...mapGetters('rental-sales', ['getTotalDataOfForms']),
    isFormChangedAsPlaceOrder() {
      return Boolean(Object.keys(this.orderInformation)?.length && Object.keys(this.customerInformation)?.length && Object.keys(this.dispatchAndReturn)?.length && Object.keys(this.eventInformation)?.length)
    },
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    isDispatchAndReturnAllRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isDispatchAndReturnAllRequiredFieldsFilled
    },
    dispatchAndReturn() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
    isEventInformationRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isEventInformationRequiredFieldsFilled
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
    rentalSalesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].rentalSalesFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.getTotalDataOfForms) !== this.rentalSalesFormClone
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    orderItemsSales() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
    isApprove() {
      return this.$route.fullPath?.includes('approve')
    },
  },

  methods: {
    submit(action) {
      this.error = {}
      this.onSubmit = true
      this.loading = action
      const getTotal = this.getTotalDataOfForms
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT) {
        setTimeout(() => {
          this.$refs.salesRentalQuoteForm.reset()
        }, 0)
      }
      setTimeout(() => {
        let filteredObj = Object.fromEntries(
          Object.entries({ action, ...getTotal }).filter(([key, value]) => value !== undefined),
        )

        filteredObj = {
          ...filteredObj,
          action,
          billing_contact_id: filteredObj?.billing_contact_id?.id,
          dispatch_warehouse_id: filteredObj?.dispatch_warehouse_id?.id,
          dispatch_method: filteredObj?.dispatch_method?.id,
          return_method: filteredObj?.return_method?.id,
          return_warehouse_id: filteredObj?.return_warehouse_id?.id,
          dispatch_contact_id: filteredObj?.dispatch_contact_id?.id,
          dispatch_address_id: filteredObj?.dispatch_address_id?.id,
          return_address_id: filteredObj?.return_address_id?.id,
          order_placed_by_id: filteredObj?.order_placed_by_id?.id,
          return_contact_id: filteredObj?.return_contact_id?.id,
          receive_method: filteredObj?.receive_method?.id,
          other_charges: filteredObj?.otherCharges?.filter(i => i?.id)?.map(i => ({
            ...i,
            unit: i.amount,
          })),
          payment_term_id: filteredObj?.payment_term_id?.id,
          event_information_rental_end_time: filteredObj?.event_information_rental_end_time?.length && filteredObj?.event_information_rental_end_time?.length >= 8
            ? filteredObj?.event_information_rental_end_time?.substring(0, 5) : filteredObj?.event_information_rental_end_time,
          event_information_rental_start_dispatch_time: filteredObj?.event_information_rental_start_dispatch_time?.length && filteredObj?.event_information_rental_start_dispatch_time?.length >= 8
            ? filteredObj?.event_information_rental_start_dispatch_time?.substring(0, 5) : filteredObj?.event_information_rental_start_dispatch_time,
          event_information_rental_end: filteredObj?.event_information_rental_end && format(filteredObj?.event_information_rental_end, 'yyyy-MM-dd'),
          event_information_rental_start_dispatch: filteredObj?.event_information_rental_start_dispatch && format(filteredObj?.event_information_rental_start_dispatch, 'yyyy-MM-dd'),
          // products: filteredObj.products,
          products: filteredObj.products?.map(product => ({
            id: product?.product_id,
            amount: product?.amount,
            type: product.type,
            notes: product?.notes,
            rental_start: product?.rental_start ? format(product?.rental_start, 'yyyy-MM-dd HH:mm') : null,
            rental_end: product?.rental_end ? format(product?.rental_end, 'yyyy-MM-dd HH:mm') : null,
          })),
          payer_account: 'renter',
        }

        delete filteredObj.otherCharges
        if (this.$route.params.id) {
          filteredObj.id = this.$route.params.id
        }

        const url = `${this.MODULE_NAME}/${this.$route.params.id ? 'update' : 'create'}`
        console.log('filteredObj', filteredObj)
        this.sendNotification(this, filteredObj, url, this.$route.params.id)
          .then(({ data }) => {
            const { id } = data.data
            this.redirectToRFQSend(id, action)
            this.clear()
          })
          .catch(err => {
            this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
            this.error = err.response?.data
            this.errorNotification(this, err)
          }).finally(() => {
            this.loading = ''
          })
      }, 1)
    },
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, true)
      }
    },
  },

  setup() {
    const {
      MODULE_NAME, quoteStatusBar,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
