<template>
  <div>
    <quote-status-bar
      :item="order"
      :quote-status-bar="quoteStatusBar"
    />
    <router-view />
    <portal to="body-footer">
      <form-action />
    </portal>
  </div>
</template>
<script >
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
// eslint-disable-next-line import/no-cycle
import { BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import FormAction from '@/views/main/orders/view/new-rental-sales/components/FormAction.vue'
import config from '../config'

export default {
  name: 'UpdateView',
  // eslint-disable-next-line vue/no-unused-components
  components: { FormAction, BButton, QuoteStatusBar },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
        const { data, success } = res.data
        console.log(data)
        // if (success && data) {
        // Customer Information
        this.customerSearchQuery = res.data.data.customer.cust_name_dba
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`, {
          customer_id: data.customer_id,
        })
        const customerInformation = {
          order_rules_required_security_deposit: data.order_rules_required_security_deposit,
          po_number: data.po_number,

          billing_contact_id: data.billing_contact,

          billing_address: data.dispatch_address ? `${getValueFromGivenObjectByKey(data.dispatch_address, 'street', '—')}, ${getValueFromGivenObjectByKey(data.dispatch_address, 'line2', '—')}, ${getValueFromGivenObjectByKey(data.dispatch_address, 'city', '—')}, ${getValueFromGivenObjectByKey(data.dispatch_address, 'state.code', '—')} ${getValueFromGivenObjectByKey(data.dispatch_address, 'zip', '—')}, ${getValueFromGivenObjectByKey(data.dispatch_address, 'country.three_letter', '—')}` : 'Customer Missing Address',
          billing_email: data.billing_contact.email,
          billing_phone: data.billing_contact.phone_one,

          deposit_requirement: data.deposit_requirement,

          price_tier: data.customer?.customer_detail?.price_tier?.label,
          customer_notes: data.customer?.customer_detail?.comments,
          order_placed_by_id: {
            ...data.order_placed_by,
            full_name: `${data.order_placed_by?.firstname} ${data.order_placed_by?.lastname}`,
          },
          receive_method: {
            id: data?.receive_method,
            name: data?.receive_method,
          },
          dispatch_contact_id: {
            id: data?.dispatch_contact?.id,
            fullName: `${data?.dispatch_contact?.first_name} ${data.dispatch_contact?.last_name}`,
          },
          dispatch_email: data?.dispatch_contact.email,
          dispatch_phone: data?.dispatch_contact.phone_one,
          return_contact_is_different: data.return_contact_is_different,
        }
        this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, customerInformation)

        // this.$store.commit(`${this.MODULE_NAME}/SET_PAYER_ACCOUNT`, {
        //   value: data.payer_account,
        // })
        //   // Insurance Information
        this.$store.commit(`${this.MODULE_NAME}/SET_INSURANCE_INFORMATION_FORM`, {
          ...data?.insurance_policy,
          coverage_start_date: data?.insurance_policy?.coverage_start_date * 1000,
          coverage_end_date: data?.insurance_policy?.coverage_end_date * 1000,
        })
        //   // EVENT INFORMATION
        this.$store.commit(`${this.MODULE_NAME}/SET_EVENT_INFORMATION_FORM`, {
          // event_information_dispatch_date: data?.event_information_rental_end * 1000,

          event_information_rental_start_dispatch: data?.event_information_rental_start_dispatch * 1000,
          event_information_rental_start_dispatch_time: data?.event_information_rental_start_dispatch_time,

          event_information_rental_end: data?.event_information_rental_end * 1000,
          event_information_rental_end_time: data?.event_information_rental_end_time,
          event_information_dispatch_date: data?.event_information_dispatch_date * 1000,

          event_reference: data?.reference,
        })
        //   // Dispatch
        this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
          dispatch_address_id: {
            id: data?.dispatch_address_id,
            fullAddress: `${data?.dispatch_address?.street}, ${data?.dispatch_address?.city}, ${data?.dispatch_address?.state?.code}, ${data?.dispatch_address?.country?.three_letter}`,
          },
          customer_discount: data.customer_discount,
          payment_term_id: {
            id: data.payment_term_id?.id,
            label: data.payment_term_id?.label,
          },
          dispatch_phone: data.dispatch_phone,
          dispatch_email: data.dispatch_email,
          return_address_id: {
            id: data?.return_address_id,
            fullAddress: `${data?.return_address?.street}, ${data?.return_address?.city}, ${data?.return_address?.state?.code}, ${data?.return_address?.country?.three_letter}`,
          },

          internal_shipping_notes: data.internal_shipping_notes,
          return_method: data?.return_method,
          order_routing_allow_dispatch_of_items_from_warehouse_of_origin: data.order_routing_allow_dispatch_of_items_from_warehouse_of_origin,
          order_routing_order_returns_to_another_warehouse: data.order_routing_order_returns_to_another_warehouse,
          dispatch_warehouse_id: data?.dispatch_warehouse,
          dispatch_method: data?.dispatch_method,
          return_warehouse_id: data?.return_warehouse,
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_PAYER_ACCOUNT`, {
          value: data.payer_account,
        })

        // ORDER ITEMS
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_SALE_RENTAL_TABLE`, { products: data.products })
        // OTHER CHARGES
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, { otherCharges: data.other_charges })
      }).finally(() => {
        this.isLoading = false
      })
    },

  },
  setup() {
    const {
      MODULE_NAME, quoteStatusBar,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
